import React from 'react';
import badge from '../components/images/EBB_Instructor_Badge.png'

export default function Experience() {
    return (
        <div id='experience' className='site-block bg-peach'>
            <p className='heading site-block-header'>
                Experience
            </p>
            <hr className='underline' />
            <div className='site-block-body container'>
                <div className='row'>
                    <div className='col-md-8'>
                        <p className='bold'>Certifications</p>
                        <ul className='info'>
                            <li>Licensed Midwife</li>
                            <li>Certified Professional Midwife</li>
                            <li>Basic Life Support (cardiopulmonary resuscitation)</li>
                            <li>Neonatal Resuscitation</li>
                            <li>TENS (transcutaneous electrical nerve stimulation)</li>
                            <li>Evidence Based Birth® Instructor</li>
                            <li>Rebozo for Labor Support</li>
                            <li>Rescue Scuba Diver</li>
                        </ul>
                        <p className='bold'>Clinical Experience</p>
                        <ul className='info'>
                            <li>Medical Assistant at the University of Washington</li>
                            <li>Rainy City Midwifery, Seattle</li>
                            <li>Lake Washington Midwives, Kirkland</li>
                        </ul>
                    </div>
                    <div className='col-md-4 text-center'>
                        <img src={badge} alt='' className='ebb-badge' />
                    </div>
                </div>
            </div>
        </div>
    )
}
