import React from "react";
import momAndBaby from "../components/images/shutterstock_442706320_1000_light.png";

export default function Fees() {
  return (
    <div id='fees' className='site-block bg-mint'>
      <p className='heading site-block-header'>Fees/ Insurance</p>
      <hr className='underline' />
      <div className='site-block-body container'>
        <div className='row'>
          <div className='info col-md-8'>
            <p>
              I am an in-network provider with most insurance plans including
              Medicaid. A benefits breakdown will be provided to you once your
              insurance information is on file. This information will help you
              determine what you will pay out of pocket, depending on your
              deductible and co-payment plan.
            </p>

            <p>Free 60-minute Consultation</p>

            <p>
              Monitrice (Advanced Doula) Services (not covered by insurance):
              $1,000
            </p>

            <p>Midwife Assistant Fee (not covered by insurance): $300</p>

            <p>1 Hour Herbal Consultation: $150</p>

            <p>
              3-hour, Private, Savvy Birth Workshop from Evidence Based Birth®
              $300
            </p>

            <p>
              I offer a sliding scale to those in financial hardships. Please
              contact me for further discussion.
            </p>
          </div>
          <div className='col-md-4 mom-with-baby'>
            <img src={momAndBaby} alt='mom with baby' />
          </div>
        </div>
      </div>
    </div>
  );
}
