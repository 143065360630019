import React from 'react';

export default function TagLine() {
    return (
        <React.Fragment>
            <div id='tagline' className='text-center light-text'>
                <p className='heading'>Personalized, Private, Family-Centered Maternity Care</p>
            </div>
        </React.Fragment>
    )
}
