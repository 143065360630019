import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

import Nav from './components/Nav';
import Logo from './components/Logo';
import TagLine from './components/TagLine';
import Welcome from './components/Welcome';
import InstagramWidget from './components/InstagramWidget';
import GetToKnow from './components/GetToKnow';
import ServicesTop from './components/ServicesTop';
import ServicesBottom from './components/ServicesBottom';
import Fees from './components/Fees';
import Experiences from './components/Experience';
import Calendly from './components/Calendly';
import Footer from './components/Footer';

class App extends Component {
  state = {
    showNav: true,
    isMobile: false,
  }

  componentDidMount() {
    this.isMobile();
  }

  toggleNav = () => {
    this.setState({ showNav: !this.state.showNav });
  }

  handleSelect = () => {
    this.setState({ showNav: !this.state.showNav });
  }

  handleInstagramClick = (e) => {
    e.preventDefault();
  }

  isMobile = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  render() {
    const closedClassNames = 'collapse navbar-collapse';
    const openClassNames = 'collapse navbar-collapse show'
    return (
      <div className='app'>
        {this.state.showNav === true ? (
          <Nav classNames={closedClassNames} onClick={this.toggleNav} onSelect={this.handleSelect} />
        ) :
          <Nav classNames={openClassNames} onClick={this.toggleNav} onSelect={this.handleSelect} />
        }
        <div className='header baby-image'>
          <Logo />
          <TagLine />
          <Welcome />
        </div>
        <div>
          <InstagramWidget onClick={this.handleInstagramClick} />
          <ServicesTop />
          <ServicesBottom />
          <GetToKnow />
          <Experiences />
          <Fees />
          <Calendly />
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;
