import React from "react";
import Monitrice from "./Monitrice";
import SavvyBirthShop from "./SavvyBirthShop";
import Herbals from "./Herbals";
import NotProvided from "./NotProvided";

export default function ServicesBottom() {
  return (
    <div id='services-bottom'>
      <div className='mwfc-bg'>
        <div className='site-block-body container'>
          <Monitrice />
          <SavvyBirthShop />
          <Herbals />
          <NotProvided />
        </div>
      </div>
    </div>
  );
}
