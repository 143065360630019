import React from "react";

export default function Monitrice() {
  return (
    <div id='monitrice'>
      <p className='bold'>Monitrice Services (Advanced Doula Services)</p>
      <div className='info light-link'>
        <p>
          If you are planning to deliver in the hospital and would like my
          presence and support, I can attend your labor and birth as a support
          person but cannot provide obstetrical services at the hospital. There
          is a large body of evidence that suggests having a doula at your labor
          and birth can improve outcomes for both you and baby.
        </p>
        <p>
          This service includes a free 90-minute consult, two prenatal visits,
          labor and birth support, and a postpartum visit at your home the day
          after you are discharged from the hospital. This services also
          includes the three-hour, Evidence Based Birth®, Savvy Birth Workshop
          and access to Evidence Based Birth® Savvy Birth online education
          including their videos, PDFs and library. Read about{" "}
          <a
            href='https://nbvd.nl/wp-content/uploads/2013/09/randomized-controlled-trial.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            doula support
          </a>{" "}
          &nbsp; here.
        </p>
      </div>
    </div>
  );
}
