import React from "react";
import Consultation from "./Consultation";
import MidwiferyCare from "./MidwiferyCare";

export default function ServicesTop() {
  return (
    <div id='services-top' className='site-block bg-blue'>
      <p className='heading site-block-header'>Services</p>
      <hr className='underline' />
      <div className='site-block-body container'>
        <Consultation />
        <MidwiferyCare />
      </div>
    </div>
  );
}
