import React from "react";

export default function SavvyBirthShop() {
  return (
    <div id='savvy'>
      <p className='bold'>Savvy Birth Workshop from Evidence Based Birth®</p>
      <p className='info'>
        Includes topics such as: how your partner can be your advocate,
        evidence-based care, overcoming fears and anxieties, navigating the
        healthcare system, providers and models of care, support, doulas,
        communicating with hospital staff and knowing your rights.
      </p>

      <button className='savvy-btn'>
        <a
          href='https://www.eventbrite.com/o/courtney-at-community-maternity-services-29778424975?s=120006787'
          target='blank'
          rel='noopener noreferrer'
        >
          Check out workshop
        </a>
      </button>
    </div>
  );
}
