import React from "react";
const dateTime = new Date().getFullYear();

export default function Footer() {
  return (
    <div id='footer' className='heading footer-block'>
      <hr />
      <div>
        <address>
          <ul>
            <li className='f-item'>
              <i className='fad fa-phone-rotary' />
              <a href='tel:+2063530540'>
                <span className='f-info'>Phone: (206) 353-0540</span>
              </a>
            </li>
            <li className='f-item'>
              <i className='fad fa-fax' />
              <span className='f-info'>Fax: (888) 851-1187</span>
            </li>
            <li className='f-item'>
              <i className='fad fa-envelope' />
              <a href='mailto:communitymaternityservices@gmail.com?subject=Please contact me regarding your services'>
                <span className='f-info'>
                  communitymaternityservices@gmail.com
                </span>
              </a>
            </li>
          </ul>
          <ul>
            <li className='f-item'>
              <i className='fad fa-map-marker-alt' />
              <a
                href='https://google.com/maps?q=1417%20NW%2054th%20ST%20STE%20228%20Seattle%20WA%2098107'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='f-info'>
                  1417 NW 54th ST STE 228 Seattle, WA 98107
                </span>
              </a>
            </li>
          </ul>
        </address>
      </div>
      <div>
        <div className='footer-places text-center'>
          <p className='mis'>Midwife in Seattle</p>
          <p>-Serving-</p>
          <div className='right'>
            Seattle, Shoreline, White Center, Mercer Island,
          </div>
          <div className='middle'>
            Bellevue, Kirkland, Issaquah, Redmond, Sammamish,
          </div>
          <div className='left'>
            and some areas in Snohomish and Kitsap County, depending on client
            load.
          </div>
        </div>
      </div>
      <p className='text-center site-footer'>
        &copy; COMMUNITY MATERNITY SERVICES {dateTime}
      </p>
    </div>
  );
}
