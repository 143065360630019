import React, { Component } from 'react';

class MidwiferyCare extends Component {
    state = {
        showMoreLBS: false,
        showMorePostpartumCare: false,
    }

    handleShowMoreLBS = () => {
        this.setState(prevState => ({ showMoreLBS: !prevState.showMoreLBS }));
    }

    handleShowMorePostpatumCare = () => {
        this.setState(prevState => ({ showMorePostpartumCare: !prevState.showMorePostpartumCare }));
    }
    render() {
        return (
            <div id='midwifery-care' >
                <p className='bold'>Midwifery Care</p>
                <div className='info dark-link pl-5 mt-2'>
                    <p className='bold'>24/7 Access to the Midwife by Phone</p>
                    <p className='info'>
                        We will establish circumstances that require an immediate call, what can wait until the next day and when to use the Client Care messenger.
               </p>

                    <p className='bold'>24/7 Access to your Medical Record</p>
                    <p className='info'>
                        With Client Care, you can access lab results, ultrasounds, prenatal flow charts and recent topics we discussed in appointments online anytime.
                </p>

                    <p className='bold'>Access to Evidence Based Birth® Savvy Birth Education</p>
                    <p className='info'>
                        Complementary access to Evidence Based Birth® videos, PDFs and library.  If you are not familiar with EBB take a look: <a href='https://evidencebasedbirth.com/' target='_blank' rel="noopener noreferrer">evidencebasedbirth.com</a>
                    </p>

                    <p className='bold'>Prenatal Appointments</p>
                    <p className='info'>
                        For your first two trimesters (up to 28 weeks), we will see each other every four weeks, or about once a month. Visits can last up to an hour, based on your individualized needs. These appointments give us a chance to get to know each other, as well as providing ample time for answering your questions and having informed discussions about your pregnancy. I will also take your vital signs, measure your belly, feel for baby’s position, and listen to baby’s heart rate at every prenatal visit.
                        I am able to collect all standard prenatal labs here in the clinic.
                        If we discuss the desire or need for an ultrasound at any time during pregnancy, I can send a referral to an ultrasound location that is convenient for you.
                        After 28 weeks, we will see each other more frequently - about every two weeks.  After 36 weeks, we begin to meet weekly until labor begins.
                </p>

                    <p className='bold'>Labor &amp; Birth Services</p>
                    {this.state.showMoreLBS ? (
                        <p className='info'>
                            I am able to attend births either in the comfort of your own home, or at one of the community’s freestanding birth centers. Once labor starts we will communicate and form a plan to meet when active labor begins.  The baby’s heart rate will be monitored by intermittent auscultation with a doppler and the birthing parent’s vitals will be monitored every few hours.
                            After your baby arrives, I will monitor you both until we are assured everyone is stable, safe and healthy. Baby’s physical exam will be performed after they have their first meal, about 1-2 hours after birth.
                            If your baby is born at a birth center, you will most likely head home within 3-4 hours after the birth so you can get some sleep in your own bed. If we are already at your home, I will tuck you into bed, tidy up, start laundry, and be on my way.
                            Waterbirth is welcomed and celebrated both at home or at the birth center. Most of the babies I catch are born into the water. It is completely safe and very effective in decreasing the intense sensations of labor. Shortly after the birth, either myself and my assistant or your partner will help you out of the tub and into a comfortable place to recover.
                            <button className='btn btn-link' onClick={this.handleShowMoreLBS}>show less</button>
                        </p>
                    ) :
                        <p className='info'>
                            I am able to attend births either in the comfort of your own home, or at one of the community’s freestanding birth centers. Once labor starts we will communicate and form a plan to meet when active labor begins. ...<button className='btn btn-link' onClick={this.handleShowMoreLBS}>show more</button>
                        </p>
                    }

                    <p className='bold'>Postpartum Care</p>
                    {this.state.showMorePostpartumCare ? (
                        <p className='info'>
                            24-48 hours after your sweet baby is born, I will come to your home to check on the both of you.  This visit includes monitoring vitals, discussing baby’s feeding habits as well as how you, the birthing parent, are recovering.  I will also offer standard newborn screenings while I am there.
                            On baby’s third day of life we will have a scheduled phone visit, and if any issues have arisen that we decide need extra attention, I will provide an additional home visit.
                            When baby is one week old, three weeks old and six weeks old, we will meet together at the clinic to continue care.
                            In Washington state, a licensed midwife is able to provide primary care to healthy newborns up to two weeks of age.  This means that I can offer routine pediatric care including monitoring growth, assessing vitals, and offering standard screenings up to two weeks of age. If anything arises in this time period that is not considered “normal,” I will make the appropriate arrangements for baby to see an additional provider. While it is unnecessary to bring baby to the pediatrician right away (I recommend the first visit at one month of age), some parents prefer to see their pediatrician right away. I support your choices and am happy to provide referrals if needed.
                            <button className='btn btn-link' onClick={this.handleShowMorePostpatumCare}>show less</button>
                        </p>
                    ) :
                        <p className='info'>
                            24-48 hours after your sweet baby is born, I will come to your home to check on the both of you.  This visit includes monitoring vitals, discussing baby’s feeding habits as well as how you, the birthing parent, are recovering. ...<button className='btn btn-link' onClick={this.handleShowMorePostpatumCare}>show more</button>
                        </p>
                    }
                </div>
            </div >
        )
    }
}

export default MidwiferyCare;

