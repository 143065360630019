import React from 'react';

export default function InstagramWidget(props) {
    return (
        <div id='community' className='instagram-widget'>
            <iframe
                title='cms-instagram-widget'
                src='https://snapwidget.com/embed/763548'
                className='snapwidget-widget'
                allowtransparency='true'
                frameBorder='0'
                scrolling='no'
                onClick={props.handleInstagramClick}
            />
        </div>
    )
};