import React, { Component } from "react";

class Nav extends Component {
  render() {
    return (
      <nav
        id='nav-bar'
        className='navbar navbar-expand-lg nav-block sticky-top'
      >
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarNav'
          aria-controls='navbarNav'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={this.props.onClick}
        >
          <span className='far fa-bars text-white'></span>
        </button>
        <div className={this.props.classNames} id='navbarNav'>
          <ul className='navbar-nav'>
            <li className='nav-item' onClick={this.props.onClick}>
              <a className='nav-link' href='#get-to-know'>
                Get To Know Me
              </a>
            </li>
            <li className='nav-item' onClick={this.props.onClick}>
              <a className='nav-link' href='#services-top'>
                Services
              </a>
            </li>
            <li className='nav-item' onClick={this.props.onClick}>
              <a className='nav-link' href='#experience'>
                Experience
              </a>
            </li>
            <li className='nav-item' onClick={this.props.onClick}>
              <a className='nav-link' href='#fees'>
                Insurance
              </a>
            </li>
            <li className='nav-item' onClick={this.props.onClick}>
              <a className='nav-link' href='#schedule'>
                Schedule
              </a>
            </li>
            <li className='nav-item' onClick={this.props.onClick}>
              <a
                className='nav-link'
                href='https://www.eventbrite.com/o/courtney-at-community-maternity-services-29778424975?s=120006787'
                target='_blank'
                rel='noopener noreferrer'
              >
                Classes
              </a>
            </li>
            <li className='nav-item' onClick={this.props.onClick}>
              <a
                className='nav-link'
                href='https://clientcarewest.net/Account/LogOn?ReturnUrl=%2f'
                target='_blank'
                rel='noopener noreferrer'
              >
                Client Care
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Nav;

//<li className='nav-item' onClick={this.props.onClick}><a className='nav-link' href='#community'>Community</a></li>
