import React from 'react';

export default function Herbals() {
    return (
        <div id='herbal'>
            <p className='bold'>Herbal Consultations</p>
            <p className='info'>
                Plant medicine has been utilized by humans for over 60,000 years. Today, very few mainstream providers are able to recommend herbal remedies because they lack the necessary training. I fully believe that a trial of herbal therapy prior to synthetic prescription drugs should be offered to people depending on the severity of the situation.

                My education in botanical actions, indications, contraindications, and safety extends to over 60 herbs for fertility, pregnancy, labor, and the postpartum period. I am able to share these herbal recommendations during prenatal visits but am always happy to extend this knowledge to others who want more information.
            </p>
        </div>
    )
}