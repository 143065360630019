import React from 'react';

export default function NotProvided() {
    return (
        <div id='not-provided'>
            <p className='bold'>Services I do not provide</p>
            <div className='info'>
                <ul>
                    <li>Medication prescriptions</li>
                    <li>Cesarean section</li>
                    <li>Forceps delivery</li>
                    <li>Vacuum delivery</li>
                    <li>Epidural or other medication for pain management</li>
                </ul>
                <p>
                    If at any time it becomes evident that these services are wanted and/or needed, I will facilitate the process of reaching out to providers who can offer this higher level of care.
                </p>
            </div>
        </div>
    )
}