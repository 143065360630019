import React, { Component } from 'react';

class Consultation extends Component {
    goToSchedule = () => {
        window.location = '#schedule';
    }

    render() {
        return (
            <div id='consultation' >
                <p className='bold'>Free 60 minute consultation</p>
                <p className='info'>
                    Let’s discuss what out of hospital birth and midwifery look like in this state, safety and what a transfer to the hospital may entail.  Please bring all of your questions to be discussed. <button className='btn btn-link' onClick={this.goToSchedule}> ...view schedule</button>
                </p>
            </div>
        )
    }
}

export default Consultation;
