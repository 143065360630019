import React from 'react';
import logo from '../components/images/logo_white-vector_solid.png'

export default function Logo() {
    return (
        <div id='logo' className='header'>
            <div className='container logo'>
                <img src={logo} alt='community maternity services logo' />
            </div>
        </div>
    )
}