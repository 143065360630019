import React from 'react'
import FamilyImage from '../components/images/family_photo.png'


export default function GetToKnow() {
    return (
        <div id='get-to-know' className='site-block get-to-know'>
            <p className='heading site-block-header'>
                Get to know Courtney
            </p>
            <hr className='underline' />
            <div className='site-block-body info'>
                <img src={FamilyImage} alt='courtneys family' />
                <div className=''>
                    <p>
                        I was born in the panhandle of Idaho and have resided in Washington state for the last 25 years.  I began my family very early in life and have been blessed with three outstanding children. In 2014, I graduated from Washington State University with a Bachelor of Arts in Social Science. My medical experience started in 2008 when I began working as a medical assistant with the University of Washington’s Department of Family Medicine. This work exposed me to a wide variety of people and their needs. What struck me the most was the need for advocacy. I wanted to expand my scope of practice and was drawn to midwifery after attending my neighbor’s birth and the birth of my nephew. I was lucky enough to find obstetric training in my own backyard and completed my Master of Science in Midwifery with a Botanical Medicine focus at Bastyr University in 2019.
                    </p>
                    <p>
                        When I’m not in clinic or at a birth, most of my free time is spent playing on the floor, reading children’s books, and picking up after my kids. My partner and I are certified divers, so we love to swim and scuba dive if time (and childcare) allows. I also enjoy cooking, making herbal tinctures, and drinking coffee.
                </p>
                    <p>
                        The decision to open a solo midwifery practice in Seattle was based on need, as most midwives practice in groups for sustainability. I wanted to fill this need by promising clients that the same provider they came to know and trust in pregnancy would be the same provider at their birth. This one-on-one relationship building is essential to my practice. I do work with professional midwife assistants in the community because having two medically trained sets of hands at a birth is much safer for parents and babies.
                </p>
                    <p>
                        I offer evidence-based information to the families I care for but expect that they will make the decisions they feel are best for themselves and their babies. I am an expert in healthy, low-risk pregnancy and childbirth. That being said, if ever a time comes where I feel things are trending outside the range of normal, I always consult with other medical providers in the community to determine if out-of-hospital delivery is still the safest option for everyone.
                </p>
                    <p>
                        Over the years, Washington state has evolved to become one of the safest areas in the country for out-of-hospital midwifery. This includes the promise of licensure, malpractice insurance, and a drug legend that allows midwives to carry medications for managing emergencies, IV fluids, antibiotics for GBS (Group B Streptococcus), oxygen, and prophylaxis for newborn babies.
                    </p>
                </div>
            </div>
        </div>
    )
}
