import React, { Component } from 'react';

class Calendly extends Component {
    state = {
        calender: null
    }
    componentDidMount() {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
        head.appendChild(script);
    }

    render() {
        return (
            <div>
                <div id='schedule'>
                    <div
                        className='calendly-inline-widget calendly'
                        data-url='https://calendly.com/communitymaternityservices/consultation?hide_landing_page_details=1'
                    >
                    </div>
                </div>
            </div>
        )
    }
}

export default Calendly;
